import React from 'react';
import {Link} from 'react-router-dom';
import Scrollchor from 'react-scrollchor';
import './header.styles.scss';

const Header = () => (
    <div className="header">
        <div className="flex-wrap">
            <Link to="/" className="header-logo">
                Jonas Davidson
            </Link>

            <nav>
                <ul>
                    <li><Link to="/portfolio">View Portfolio</Link></li>
                    <li><Scrollchor to="contact">Contact</Scrollchor></li>
                </ul>
            </nav>
        </div>
    </div>
)

export default Header;