import React from 'react';
import ProjectModal from '../modal/modal.component';
import './portfolio-listing-items.styles.scss';
class PortfolioListingItems extends React.Component{
    constructor(props){
        super(props);
        this.state={
            activeProject: null,
            modalOpen: false
        }
    }

    clickProject = (e) => {
        e.preventDefault();
        this.setState({ 
            modalOpen: true,
            activeProject: this.props.projects.filter(function(project){
                return project.slug === e.currentTarget.getAttribute('data-id');
            })
        });
    }

    closeModal = () => {
        this.setState({
            modalOpen: false,
            activeProject: null
        })
    }

    render(){  
        
        return(
            <div className="portfolio-loop">
                <div className="container">
                    {
                        this.props.projects.map(project => (
                            <div 
                                className="project-item" 
                                key={project.id} 
                                data-id={project.slug}
                                onClick={this.clickProject}
                            >
                                <div className="sizer"></div>
                                <div 
                                    className="project-background"
                                    style={{
                                        backgroundImage: `url(${project.portfolio_screenshot.url})`
                                    }}
                                >
                                </div>
                                <h3 dangerouslySetInnerHTML={{__html: project.title.rendered}} />
                            </div>
                    ))} 
                </div>
                {this.state.activeProject && 
                    <ProjectModal 
                        isOpen={this.state.modalOpen}
                        closeModal={this.closeModal}
                        activeProject={this.state.activeProject[0]} 
                    />
                }
            </div>
        );
    }
}

export default PortfolioListingItems;
