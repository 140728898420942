import React from 'react';
import {ReactComponent as DownArrow} from '../../images/down-caret.svg';
import './portfolio-filters.styles.scss';

const PortfolioFilters = ({
        categories, 
        clickFilter, 
        activeFilter, 
        searchField, 
        activeFilterTitle, 
        clickPlaceholder,
        openFilters
    }) => (
    <div className="portfolio-filters">
        <div className={!openFilters && !activeFilter ? 'inactive mobile-filters-wrap' : 'mobile-filters-wrap' }>
            <div className="placeholder" onClick={clickPlaceholder}>
                {activeFilterTitle}
                <DownArrow />
            </div>
            {!searchField && openFilters &&
            <ul className="mobile-filter-items">
                <li className="mobile-filter-item" data-id={null} onClick={clickFilter}>All</li>
                {
                    categories.map( category => (
                        <li 
                            className="mobile-filter-item"
                            data-id={category.id}
                            onClick={clickFilter}
                            key={category.id}
                        >
                            {category.name}
                        </li>
                    ))
                }
            </ul>
            }
        </div>
        <button className={activeFilter === null && searchField === '' ? 'active-filter category-filter smaller-button' : 'category-filter smaller-button'} data-id={null} onClick={clickFilter}>All</button>
        {
            categories.map( category => (
                <button 
                    className={parseInt(activeFilter) === parseInt(category.id) && searchField === '' ? 'active-filter category-filter smaller-button' : 'category-filter smaller-button'}
                    data-id={category.id}
                    onClick={clickFilter}
                    key={category.id}
                >
                    {category.name}
                </button>
            ))
        }
    </div>
);

export default PortfolioFilters;