import React from 'react';
import { Switch, Route } from 'react-router-dom';
import './App.scss';
import "animate.css/animate.min.css";
import Header from './components/header/header.component';
import Contact from './components/contact/contact.component';
import Footer from './components/footer/footer.component';
import Homepage from './pages/homepage/homepage.component';
import Portfolio from './pages/portfolio/portfolio.component';

class App extends React.Component{
  constructor(){
    super()

    this.state = {
      dataUrl: '//jonasdavidson.com/api/wp-json/wp/v2/portfolio_items',
      projectImgPreload: [],
      initialRender: false
    }

    
  }



  componentDidMount(){
    fetch(this.state.dataUrl)
        .then(response => response.json())
        .then(projectPreload => this.setState({ projectImgPreload: projectPreload}));

      setTimeout(() => {
          this.setState({ 
              initialRender: true
          });
      }, 1000)
  }
  render(){
    return(
      <div className="App">
        <Header />
        <Switch>
          <Route 
            exact 
            path='/'
            render={(props) => <Homepage initialRender={this.state.initialRender} />}
           />
          <Route 
            exact 
            path='/portfolio' 
            component={Portfolio} 
          />
        </Switch>
        <Contact />
        <Footer />
        <div className="preload"
          style={{
            display: 'none'
          }}
        >
          {
            this.state.projectImgPreload.map((preloadImg, index) =>(
              <img src={preloadImg.portfolio_screenshot.url} key={index} alt="" />
            ))
          }
        </div>
    </div>
    );
  }
}

export default App;
