import React from 'react';

import './homepage.styles.scss';

import Hero from '../../components/hero/hero.component';
import About from '../../components/about/about.component';
import TrustedBy from '../../components/trusted-by/trusted-by.component';
import LatestProjects from '../../components/latest-projects/latest-projects.component';

class Homepage extends React.Component{
    constructor(props){
        super(props);
        this.state = {}
    }

    componentDidMount(){
        document.title = 'Jonas Davidson | Santa Cruz Wordpress, Shopify and Craft CMS Developer';
    }

    render(props){
        return(
            <div className='homepage' >
                <Hero >
                Your friendly Santa Cruz web developer specializing in Wordpress, Shopify & Craft CMS
                </Hero>
                <About />
                <TrustedBy />
                <LatestProjects />
            </div>
        )
    }
}


export default Homepage;