import React from 'react';
import './portfolio-listing.styles.scss';

import PortfolioListingItems from '../portfolio-listing-items/portfolio-listing-items.component';
import SearchBox from '../search-box/search-box.component';
import PortfolioFilters from '../portfolio-filters/portfolio-filters.component';

const defaultPlaceholder = 'Filter by Category';

class PorfolioListing extends React.Component{
    constructor(){
        super();
        this.state = {
            projects: [],
            projectCats: [],
            searchField: '',
            activeFilter: null,
            activeFilterTitle: defaultPlaceholder,
            openFilters: false,
            projectsDataUrl: '//jonasdavidson.com/api/wp-json/wp/v2/portfolio_items?per_page=100',
            projectCatDataUrl: '//jonasdavidson.com/api/wp-json/wp/v2/portfolio_item_category'
        }
    }

    componentDidMount() {
        fetch(this.state.projectsDataUrl)
        .then(response => response.json())
        .then(projectData => this.setState({ projects: projectData }));

        fetch(this.state.projectCatDataUrl)
        .then(response => response.json())
        .then(projectCatData => this.setState({ projectCats: projectCatData }));
    }

    handleChange = (e) => {
        this.setState({ 
            searchField: e.target.value,
            activeFilter: null,
            activeFilterTitle: defaultPlaceholder 
        });
    }

    clickFilter = (e) => {
        this.setState({ 
            activeFilter: e.target.getAttribute('data-id'),
            searchField: '',
            openFilters: !this.state.openFilters
        });

        if (e.target.textContent === 'All') {
            this.setState({
                activeFilterTitle: defaultPlaceholder
            });
        } else {
            this.setState({
                activeFilterTitle: e.target.textContent
            });
           
        }
    }

    clickPlaceholder = () => {
        this.setState({
            searchField: '',
            openFilters: !this.state.openFilters
        });
    }

    render(){
        const { projects, searchField, activeFilter } = this.state;
        const filteredProjects = projects.filter(project => {
            if(activeFilter) {
               return project.portfolio_item_category.includes(parseInt(activeFilter))
            } else {
               return project.content.rendered.toLowerCase().replace(/<[^>]*>?/gm, '').includes(searchField.toLowerCase()) || project.title.rendered.toLowerCase().includes(searchField.toLowerCase())
            }
        });
            
        return(
            
            <div className="portfolio-listing">
                <div className="page-header">
                    <div className="container">
                        <h1>Web Development Portfolio</h1>
                    </div>
                </div>
                <div className="filters-wrap container">
                    <PortfolioFilters 
                        categories={this.state.projectCats}
                        clickFilter={this.clickFilter}
                        activeFilter={this.state.activeFilter}
                        searchField={this.state.searchField}
                        activeFilterTitle={this.state.activeFilterTitle}
                        clickPlaceholder={this.clickPlaceholder}
                        openFilters={this.state.openFilters}
                    />
                    <SearchBox 
                        placeholder="Search Projects"
                        handleChange={this.handleChange}
                        searchValue={searchField}
                        activeFilter={this.state.activeFilter}
                    />
                </div>
                <PortfolioListingItems 
                    projects={filteredProjects} 
                />
            </div>
        )
    }
}

export default PorfolioListing;