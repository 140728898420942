import React from 'react';

import './footer.styles.scss';

const Footer = () => (
    <div className="site-footer">
    <p>&copy; {(new Date().getFullYear())} jonasdavidson.com</p>
    </div>
);

export default Footer;