import React from 'react';
import './trusted-by.styles.scss';
import Client from '../client/client.component';

class TrustedBy extends React.Component{
    constructor(props){
        super(props);
        this.state={
            dataUrl: '//jonasdavidson.com/api/wp-json/wp/v2/pages/45',
            trustedClients: []
        }
    }

    componentDidMount(){
        fetch(this.state.dataUrl)
        .then(response => response.json())
        .then(tBData => this.setState({ trustedClients: tBData.client }));
    }

    render(){
        return(
            <div className="trusted-by">
                <h2 className="section-header">Trusted By</h2>
                <div className="clients-listing-row container">
                    {
                        this.state.trustedClients.map((client, id) => (
                            
                            <Client key={id} client={client} />

                        ))
                    }
                </div>
            </div>
        );
    }
}

export default TrustedBy;