import React from 'react';
import './about.styles.scss';

class About extends React.Component{
    constructor(props){
        super(props);
        this.state={
            aboutData: {},
            dataRoute: '//jonasdavidson.com/api/wp-json/wp/v2/pages/29'
        }
    }

    componentDidMount(){
        fetch(this.state.dataRoute)
        .then(response => response.json())
        .then(aboutData => this.setState({ aboutData: aboutData }));  
    }

    render(){
        return(
            <div className='about'>
                <div className="container">
                    {this.state.aboutData.home_about_section_title &&
                        <h2 className="section-header">
                            {this.state.aboutData.home_about_section_title}
                        </h2>
                    }

                    {this.state.aboutData.about_section_flex_content && 
                    <article className="section-content">
                       {this.state.aboutData.about_section_flex_content.map((flexItem, index) =>
                            {   
                                if (flexItem.acf_fc_layout === 'text_option') 
                                return (
                                    <div key={index} className="text-option content-section" dangerouslySetInnerHTML={{__html: flexItem.text_field}} />
                                )
                                else if (flexItem.acf_fc_layout === 'image_option')
                                return (
                                    <div key={index} className="image-option content-section">
                                        <img key={index} src={flexItem.image_field.url} alt={flexItem.image_field.alt} />
                                    </div>
                                )
                                return true;
                            }
                        )} 
                    </article>
                    }
                </div>
            </div>
        );
    }
}
export default About;