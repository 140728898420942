import React from 'react';
import Modal from 'react-modal';

import './modal.styles.scss';
import {ReactComponent as CloseX} from '../../images/close-x.svg';
 
const ProjectModal = ({isOpen, activeProject, closeModal}) => (
    <Modal 
        className="project-modal"
        isOpen={isOpen} 
        shouldCloseOnOverlayClick={true}
        shouldCloseOnEsc={true}
    >
        <div className="modal-interior">
            <div className="close-modal" onClick={closeModal}><CloseX /></div>
            
            <img src={activeProject.portfolio_screenshot.url} alt={activeProject.title.rendered + ' website screenshot'} />
            <div className="modal-text-wrap">
                <h3 className="section-header" dangerouslySetInnerHTML={{__html: activeProject.title.rendered}} />
                <div className="the-content"  dangerouslySetInnerHTML={{__html: activeProject.content.rendered}} />
                <div className="button-wrap">
                    <a className="button" href={activeProject.project_url} target="blank">View Project</a>
                </div>
            </div>
        </div>
    </Modal>
);

Modal.setAppElement('#root');
export default ProjectModal;