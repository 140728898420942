import React from 'react';
import './search-box.styles.scss';

const SearchBox = ({placeholder, handleChange, searchValue, activeFilter}) => (
    <div className={!searchValue ? "search-box-wrap inactive" : "search-box-wrap"}>
        <input
            className="search"
            placeholder={placeholder}
            onChange={handleChange}
            type="search"
            value={searchValue}
        />
    </div>
);

export default SearchBox;
