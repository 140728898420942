import React from 'react';
import axios from 'axios';
import './contact.styles.scss';

const API_PATH = 'https://www.jonasdavidson.com/contact-script/index.php';

class Contact extends React.Component{
    constructor(props){
        super(props);
        this.state={
            fname: '',
            lname: '',
            email: '',
            message: '',
            honeypot: '',
            mailSent: false,
            mailSentTwo: false,
            error: null
        }
    }

    handleFormSubmit = event => {
        event.preventDefault();
        if(!this.state.honeypot) {
            axios({
                method: 'post',
                url: `${API_PATH}`,
                headers: { 'content-type': 'application/json' },
                data: this.state
            })
            .then(result => {
                this.setState({
                mailSent: result.data.sent
                })
            })
            .catch(error => this.setState({ error: error.message }));
        } else {
            this.setState({
                mailSent: true,
                mailSentTwo: true
            })     
        }
    }

    render(){
        return(
            <div id="contact">
                <div className="container">
                {!this.state.mailSent &&
                <div>
                <h2 className="section-header">Interested in working with me?</h2>
                <h3 className="smaller-header">Drop me a line.</h3>
                    <form action="#" autoComplete={"off"}>
                        <div className="input-group">
                            <input 
                                type="text" 
                                id="fname" 
                                name="firstname"
                                className="form-input"
                                placeholder="First Name"  
                                value={this.state.fname}
                                onChange={e => this.setState({ fname: e.target.value })}
                            />
                        </div>
                        <div className="input-group">
                            <input 
                                type="text" 
                                id="lname" 
                                name="lastname" 
                                placeholder="Last Name"
                                className="form-input"
                                value={this.state.lname}
                                onChange={e => this.setState({ lname: e.target.value })}
                            />
                        </div>
                        <div className="input-group">
                            <input 
                                type="email" 
                                id="email" 
                                name="email"
                                className="form-input"
                                placeholder="Email"
                                value={this.state.email}
                                onChange={e => this.setState({ email: e.target.value })} 
                            />
                        </div>
                        <div className="input-group body-message-input">
                            <input 
                                type="text" 
                                id="bmessage" 
                                name="body-message"
                                className="form-input"
                                placeholder="Body Message"  
                                autoComplete={"off"}
                                value={this.state.honeypot}
                                onChange={e => this.setState({ honeypot: e.target.value })}
                            />
                        </div>
                        <div className="input-group">
                            <textarea 
                                id="subject" 
                                name="subject" 
                                className="form-input"
                                placeholder="Message"
                                value={this.state.message}
                                onChange={e => this.setState({ message: e.target.value })}
                            >
                            </textarea>
                        </div>
                        <div className="center-align">
                            <input className="button" type="submit" onClick={e => this.handleFormSubmit(e)}  value="Submit" />
                        </div>
                        <div className="thank-you-message">
                        
                        </div>
                    </form>
                    </div>
                }
                    {this.state.mailSent && !this.state.mailSentTwo &&
                        <div className="success-message smaller-header">Thank you for contacting me.<br/>I&rsquo;ll be in touch shortly.</div>
                    }
                    {this.state.mailSentTwo &&
                        <div className="success-message smaller-header">There seems to be a problem with your submission.<br/>We are currently looking into this issue.</div>
                    }
                </div>
            </div>
        );
    }
}

export default Contact;