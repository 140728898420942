import React from 'react';
import './hero.styles.scss';

class Hero extends React.Component{
    constructor(props){
        super(props);
        this.state={
            heroClass: 'hero',
            dataUrl: '//jonasdavidson.com/api/wp-json/wp/v2/pages/36',
            heroData : ''
        }
    }

    componentDidMount() {

        fetch(this.state.dataUrl)
        .then(response => response.json())
        .then(heroData => this.setState({ heroData: heroData }));
        
        if (this.props.initialRender) {
            this.setState({ 
                heroClass: 'hero show-image'
            });
        } else {
            setTimeout(() => {
                this.setState({ 
                    heroClass: 'hero show-image'
                });
            }, 1000)
        }
        
    }

    render(){
        return(
            <div className={this.state.heroClass}>
            {this.state.heroData.home_hero_image &&
                <div 
                    className="background-image"
                    style={{
                        backgroundImage: `url(${this.state.heroData.home_hero_image.url})`
                    }}
                ></div>
            }
                <div className="container">
                    <h1>{this.props.children}</h1>
                </div>
            </div>
        )
    }
}

export default Hero;