import React from 'react';
import { ReactComponent as ArrowLink }  from '../../images/arrow-right.svg';
import './latest-project.styles.scss';

const LatestProject = ({title, content, portfolio_screenshot, project_url}) => (
    <div className="latest-project">
        <div className="screenshot-wrap">
            <img className="screenshot" src={portfolio_screenshot.url} alt={title.rendered} />
        </div>
        <div className="latest-project-text">
            <h3>{title.rendered}</h3>
            <div className="recent-content" dangerouslySetInnerHTML={{__html: content.rendered}} />
            <a className="arrow-link" href={project_url} target="_blank" rel="noopener noreferrer">View Project <ArrowLink /></a>
        </div>
    </div>
);

export default LatestProject;