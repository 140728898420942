import React from 'react';

import './portfolio.styles.scss';

import PorfolioListing from '../../components/portfolio-listing/portfolio-listing.component';

class Portfolio extends React.Component{
    constructor(){
        super();
        this.state = {}
    }

    componentDidMount(){
        document.title = 'Jonas Davidson | Web Developer Portfolio Santa Cruz, CA';
        window.scrollTo(0, 0);
    }

    render(){
        return(
            <PorfolioListing />
        );
    }
}


export default Portfolio;