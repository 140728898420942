import React from 'react';
import {Link} from 'react-router-dom';
import './latest-projects.styles.scss';
import LatestProject from '../../components/latest-project/latest-project.component';

class LatestProjects extends React.Component{
    constructor(){
        super();
        this.state = {
            projects:[],
            sectionHeader: 'Latest Projects'
        }
    }

    componentDidMount() {
        fetch('//jonasdavidson.com/api/wp-json/wp/v2/portfolio_items?per_page=100')
        .then(response => response.json())
        .then(projectData => this.setState({ projects: projectData }));
      }


    render(){
        const filteredProjects = this.state.projects.filter(function(project){
            return project.feature_project === 'yes';
        });
        return(
            <div className="latest-projects">
                <div className="container">
                <h2 className="section-header">{this.state.sectionHeader}</h2>
                    <div className="latest-projects-items">
                        {filteredProjects.map(({id, ...otherProps}) => (
                        <LatestProject key={id} {...otherProps} />
                        ))}
                    </div>
                    <Link className="button" to="/portfolio">View All Projects</Link>
                </div>
            </div>
        );
    }
};

export default LatestProjects;