import React from 'react';
import './client.styles.scss';

const Client = (props) => (
    <div className="client">
        <img src={props.client.client_logo.sizes.thumbnail} alt={props.name} />
        <h3>{props.client.client_name}</h3>
    </div>
);

export default Client;